import React from 'react'
import { MAIN_PHONE_NUMBER } from '@constants/index'
import { ExternalPaths } from '@shared/menu-items/paths'
import FullLogo from '@shared/svg/full-logo.svg'
import SkolkovoLogo from '@shared/svg/skolkovo.svg'
import cn from 'classnames'
import Link from 'next/link'

import { useRegions } from '../../../../regions/provider'
import { AppContent } from '../../sections/app'
import { Socials } from '../../socials'

import styles from './style.module.scss'

interface Props {
	FooterContentBlock: JSX.Element
}

export const NewLPFooter = ({ FooterContentBlock }: Props) => {
	const regions = useRegions()

	return (
		<>
			<section className={styles.top}>
				<Link href='/' className={styles.logoWrap}>
					<FullLogo className={styles.logo} />
				</Link>
				<div className={styles.rightPart}>
					<Socials className={styles.nlpSocials} withoutOD />
					<div className={cn(styles.contacts, styles.nlpContacts)}>
						<a href={`tel:${MAIN_PHONE_NUMBER.replace(/\s/g, '')}`}>{MAIN_PHONE_NUMBER}</a>
						<p className={styles.smallText}>Звонки по России бесплатно</p>
					</div>
				</div>
			</section>
			<section>{FooterContentBlock}</section>
			<section>
				<div className={cn(styles.light, styles.singleCookie)}>
					Мы&nbsp;используем Cookies для сбора обезличенных данных. Они полезны для статистики,
					анализа трафика и&nbsp;настройки подходящей рекламы. Оставаясь на&nbsp;сайте,
					вы&nbsp;соглашаетесь на&nbsp;сбор таких данных.
				</div>
				<div className={styles.blockWrap}>
					<div className={styles.rightBlock}>
						<div className={styles.logosWrap}>
							<a
								href={ExternalPaths.skolkovo}
								target='_blank'
								rel='noopener noreferrer nofollow'
								className={styles.logosItem}
							>
								<SkolkovoLogo className={cn(styles.logosSkolkovo, styles.logosLogo)} />
								<span>Участник проекта инновационного центра «Сколково»</span>
							</a>
						</div>
						<div className={styles.appWrap}>
							<AppContent
								TitleComponent={
									<h5 className={cn(styles.light, styles.appTitle)}>
										Мобильное приложение Credit.Club Деньги
									</h5>
								}
								headerStyles={styles.appHeader}
								descriptionStyles={cn(styles.light, styles.appDesc)}
							/>
						</div>
					</div>
					<div className={styles.leftBlock}>
						<div className={styles.nlpWrapper}>
							<p className={cn(styles.light)}>
								© 2019—{new Date().getFullYear()} ООО «Кредит.Клаб»
							</p>
							<p className={styles.light}>
								ОГРН 1196658084743
								<br />
								ИНН 6678105594
							</p>
							<p className={styles.light}>
								620075, Россия, Свердловская&nbsp;обл., Екатеринбург, ул.&nbsp;Малышева,
								стр.&nbsp;51, офис&nbsp;25/07
								<br />
								Платформа цифровых решений. Модули программы Информационной системы Credit.Club
								предоставлены по лицензионным договорам КПК «Фонд Ипотечного Кредитования» и ООО МКК
								«Магазин кредитов»
							</p>
							<p className={styles.light}>
								Сервис не является деятельностью оператора инвестиционной платформы по содействию в
								инвестировании и в предоставлении займов с использованием инвестиционной платформы,
								предоставляются технические возможности модулей программы для ЭВМ.
							</p>
							<div className={cn(styles.light, styles.partCookie)}>
								Мы&nbsp;используем Cookies для сбора обезличенных данных. Они полезны для
								статистики, анализа трафика и&nbsp;настройки подходящей рекламы. Оставаясь
								на&nbsp;сайте, вы&nbsp;соглашаетесь на&nbsp;сбор таких данных.
							</div>
							<p className={styles.light}>
								Под кредитом понимаются экономические отношения, которые заключаются в получении
								заёмщиком от кредитора денежных средств на условиях возврата и платности, в том
								числе по договору займа.
							</p>
						</div>
						<div className={cn(styles.nlpRegions, styles.mobileRegions)}>
							<a
								className={cn(styles.light, styles.nlpRegionLink, 'main-link')}
								href='mailto:platform@credit.club'
							>
								platform@credit.club
							</a>
							<span className={styles.currentCity} onClick={regions.openPicker}>
								{regions.currentCity?.displayName}
							</span>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
