import React, { Fragment } from 'react'
import { MAIN_PHONE_NUMBER } from '@constants/index'
import { LoginAs } from '@shared/components/login-as'
import { FullLogo } from '@shared/components/logo'
import { FooterBaseOwnProps } from '@shared/components/page-layout/model'
import { Socials } from '@shared/components/socials'
import { Spoiler } from '@shared/components/spoiler'
import {
	footerItemsFirst,
	footerItemsFourth,
	footerItemsSecond,
	footerItemsThird,
} from '@shared/menu-items/footer-items'
import { MenuItem } from '@shared/menu-items/model'
import { ExternalPaths, Paths } from '@shared/menu-items/paths'
import SkolkovoLogo from '@shared/svg/skolkovo.svg'
import { isNotEmpty } from '@shared/utils/helpers'
import cx from 'classnames'
import { isEmpty } from 'lodash'
import Link from 'next/link'

import { useRegions } from '../../../../regions/provider'

import styles from './style.module.scss'

const Logos = () => (
	<div className={styles.logos}>
		<a
			href={ExternalPaths.skolkovo}
			target='_blank'
			rel='noopener noreferrer nofollow'
			className={styles.logosItem}
		>
			<SkolkovoLogo className={cx(styles.logosSkolkovo, styles.logosLogo)} />
			<span>Участник проекта инновационного центра «Сколково»</span>
		</a>
	</div>
)

export const BaseFooter = ({
	enableNavItems,
	hideSocials,
	hideLoginAs,
	linkToDocuments,
	isNavsHidden,
	extraContent,
}: FooterBaseOwnProps) => {
	const regions = useRegions()
	const renderListItem = ({ name, path }: MenuItem, isSubItem?: boolean) => {
		if (enableNavItems && !enableNavItems.includes(path!)) return null

		const href =
			path === Paths.sitemap && regions.cityFromUrl ? `/${regions.cityFromUrl}${path}` : path

		return (
			<li className={cx(styles.navItem, { [styles.navSubItem]: isSubItem })} key={name}>
				{name === 'Банкам' ? (
					<a key={name} href={path} target='_blank' rel='noopener noreferrer nofollow'>
						{name}
					</a>
				) : (
					<Link key={name} href={href!}>
						{name}
					</Link>
				)}
			</li>
		)
	}

	const renderSubItems = (items: MenuItem[]) => {
		const subItems = enableNavItems
			? items.filter((item) => enableNavItems.includes(item.path!))
			: items

		if (isEmpty(subItems)) return null

		return (
			<ul className={styles.navSubList}>{subItems.map((item) => renderListItem(item, true))}</ul>
		)
	}

	const renderNavList = (item) => (
		<Fragment key={item.name}>
			{renderListItem(item)}
			{isNotEmpty(item.items) && renderSubItems(item.items)}
		</Fragment>
	)

	return (
		<>
			<div className={styles.row}>
				<div className={`${styles.col} ${styles.logoWrapper}`}>
					<div>
						<Link href='/' className={styles.logoWrap}>
							<FullLogo className={styles.logo} />
						</Link>
						{linkToDocuments && (
							<Link href='/documents' className={styles.link}>
								Документы и раскрытие информации
							</Link>
						)}
					</div>
					{!hideSocials && <Socials withoutOD className={styles.socials} />}
				</div>
				<div className={`${styles.col} ${styles.contactWrapper}`}>
					<div className={styles.contacts}>
						<a href={`tel:${MAIN_PHONE_NUMBER.replace(/\s/g, '')}`}>{MAIN_PHONE_NUMBER}</a>
						<p className={styles.smallText}>Звонки по России бесплатно</p>
					</div>
					{!hideLoginAs && (
						<LoginAs linksClassName={styles.entranceLink} className={styles.entrance} />
					)}
				</div>
			</div>
			{!isNavsHidden && (
				<nav className={styles.nav}>
					{enableNavItems ? (
						<ul className={styles.navList}>
							{footerItemsFirst.map(renderNavList)}
							{footerItemsSecond.map(renderNavList)}
						</ul>
					) : (
						<>
							<ul className={cx(styles.navList, styles.first)}>
								{footerItemsFirst.map(renderNavList)}
							</ul>
							<ul className={cx(styles.navList, styles.second)}>
								{footerItemsSecond.map(renderNavList)}
							</ul>
							<ul className={cx(styles.navList, styles.third)}>
								{footerItemsThird.map(renderNavList)}
							</ul>
							<ul className={cx(styles.navList, styles.fourth)}>
								{footerItemsFourth.map(renderNavList)}
							</ul>
						</>
					)}
				</nav>
			)}
			{extraContent && extraContent}
			<div className={cx(styles.info)}>
				<div className={cx(styles.cookiesInfo)}>
					<span className={styles.lightText}>
						Мы&nbsp;используем Cookies для сбора обезличенных данных. Они полезны для статистики,
						анализа трафика и&nbsp;настройки подходящей рекламы. Оставаясь на&nbsp;сайте,
						вы&nbsp;соглашаетесь на&nbsp;сбор таких данных.
						<span className={cx(styles.padding)}>
							Под кредитом понимаются экономические отношения, которые заключаются в&nbsp;получении
							заёмщиком от кредитора денежных средств на условиях возврата и&nbsp;платности, в том
							числе по&nbsp;договору займа.
						</span>
					</span>
					<Logos />
				</div>
				<div className={styles.privacy}>
					<div>
						<div>
							<span className={styles.lightText}>
								© 2019—{new Date().getFullYear()} ООО «Кредит.Клаб»
								<br />
								ОГРН 1196658084743
								<br />
								ИНН 6678105594
								<br />
								<br />
							</span>
							<a className={cx(styles.lightText, 'main-link')} href='mailto:platform@credit.club'>
								platform@credit.club
							</a>
							<span className={styles.currentCity} onClick={regions.openPicker}>
								{regions.currentCity?.displayName}
							</span>
						</div>
						<Logos />
					</div>
					<Spoiler className={styles.lightText} buttonText='Варианты выдачи'>
						1.{' '}
						<a
							className='main-link'
							target='_blank'
							rel='noopener noreferrer nofollow'
							href={ExternalPaths.mk}
						>
							ООО МКК «Магазин кредитов»
						</a>{' '}
						ИНН 6678014749, регистрационный номер записи в реестре ЦБ РФ 651303465.
						<br />
						<br />
						2.{' '}
						<a
							className='main-link'
							target='_blank'
							rel='noopener noreferrer nofollow'
							href={ExternalPaths.loanfund}
						>
							КПК «Фонд Ипотечного Кредитования»
						</a>{' '}
						ИНН 6678102434, запись в реестре ЦБ РФ от 07.06.2019.
						<br />
						<br />
						3. Инвесторы через оператора инвестиционной платформы ООО «Кредит.Клаб» ИНН 6678105594,
						запись в реестре ЦБ РФ от 29.01.2021. Протокол Ассоциации операторов инвестиционных
						платформ №30-06/20 от 30 июня 2020 года. <br /> <br /> Профильные поднадзорные Банку
						России финансовые организации осуществляют самостоятельную деятельность в соответствии с
						федеральным законодательством. Оператор инвестиционной платформы ООО «Кредит.Клаб»
						осуществляет комплексное обслуживание финансовых организаций, инвесторов и заемщиков
						посредством информационных технологий.
					</Spoiler>
				</div>
			</div>
		</>
	)
}
